.workExperienceContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.centerH2 {
  text-align: center;
}

.cardList {
  display: flex;
  justify-content: flex-start;
  padding: 3rem;
  overflow-x: scroll;
}

.cardList::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.cardList::-webkit-scrollbar-thumb {
  background: #424242;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 50%, 0.25);
}

.cardList::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #2d2e2d, #2d2e2d 1px, #525352 0, #525352),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-width: 1366px) {
  .workExperienceContainer {
    align-items: center;
  }

  .cardList {
    max-width: 1250px;
    justify-content: center;
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 3%,
      black 97%,
      transparent 100%
    );
  }
}
