/* Color pallett 
From the brightest to darkest
#D0D1D0

#A4A5A4

#797A79

#525352

#2d2e2d
*/

body {
  color: #ffffff;
}

.container {
  background-image: linear-gradient(to bottom, #525352, #313231);
}
