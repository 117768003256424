.aboutMeSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 22px;
  min-height: 100vh;
}

.aboutMeSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.profilePicture {
  width: 200px;
  height: auto;
  border-radius: 50%;
}

.profileText {
  padding: 0 1em;
  min-width: 250px;
}

.list {
  list-style: none;
  padding: 0;
  font-size: 22px;
  text-align: left;
}

.listItemWithIcon {
  text-align: left !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.listItemWithIconText {
  width: 50%;
}

.listItemWithIconIcon {
  text-align: center;
  width: 2.5em;
}

@media only screen and (min-width: 768px) {
  .aboutMeSectionContainer {
    max-width: 1250px;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .profileText {
    max-width: 500px;
  }
}
