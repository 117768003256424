.socialMediaBar {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0;
  width: 100%;
  font-size: 4em;
}

a {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.5s;
}

a:hover {
  color: #959595;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .socialMediaBar {
    max-width: 50%;
  }
}
