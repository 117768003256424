.card {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 350px;
    width: 400px;
    min-width: 250px;
    padding: 1.5rem;

    border-radius: 16px;
    box-shadow: -1rem 0 1rem #1f1f1f;
    background: #2d2e2d;

    transition: .2s;
}

.card:hover {
    transform: translateY(-1rem);
}

.card:hover~.card {
    transform: translateX(130px);
}

.card:not(:first-child) {
    margin-left: -130px;
}